import * as React from "react"
import { useEffect, useContext } from "react"
import ScrollSafeContext from "../context/scrollSafeContext"
import ReactGA from "react-ga"

// Components
import SEO from "../components/seo"
import ContactHero from "../components/ContactHero"
import ContactBody from "../components/ContactBody"
import ContactLocation from "../components/ContactLocation"
import ContactFullImage from "../components/ContactFullImage"

// Animations
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Interfaces
interface ContactPageProps {}

// SEO
const seo = {
  title: "Contact",
  description: "Get in touch with Silk Studio either through our contact form or social channels.",
  keywords: [
    "digital design studio",
    "web design shrewsbury",
    "web design midlands",
    "freelance web developer",
    "brand design uk",
    "freelance brand design",
  ],
}

/*









*/

const ContactPage: React.FunctionComponent<ContactPageProps> = () => {
  // Context
  const { scrollSafe, setScrollSafe } = useContext(ScrollSafeContext)

  // Effects
  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      setScrollSafe?.(true)
    }, 600)

    ReactGA.pageview(window.location.pathname + window.location.search)

    return () => {
      setScrollSafe?.(false)
    }
  }, [])

  return (
    <>
      <SEO title={seo.title} description={seo.description} keywords={seo.keywords} />
      {scrollSafe && (
        <>
          <ContactHero />
          <ContactBody />
          <ContactLocation />
          <ContactFullImage />
        </>
      )}
    </>
  )
}

export default ContactPage
