import { gsap } from "gsap"

// Utils
import { $desktop, $tablet } from "../../utils/breakpoints"

/*








*/

/**
 * @animation
 *
 * @description Animate title in
 * @returns {gsap.core.Timeline} Gsap timeline
 */
export const titleAnim = (): gsap.core.Timeline =>
  gsap
    .timeline()
    .set(["#title-original", "#title-functional", "#title-responsible"], {
      color: "rgba(250, 250, 250, 1)",
    })
    .from("#title-original", {
      y: "200%",
      skewY: "25deg",
      duration: 0.9,
      ease: "expo.out",
    })
    .from("#title-functional", {
      y: "200%",
      skewY: "25deg",
      duration: 0.9,
      ease: "expo.out",
    })
    .from("#title-responsible", {
      y: "200%",
      skewY: "25deg",
      duration: 0.9,
      ease: "expo.out",
    })
    .fromTo(
      ".o-hero-title__text",
      {
        color: "rgba(250, 250, 250, 0)",
      },
      {
        color: "rgba(250, 250, 250, 1)",
        duration: 1.5,
        ease: "linear",
      },
      "-=0.15"
    )
    .to(["#title-original", "#title-functional", "#title-responsible"], {
      color: "inherit",
    })

/**
 * @animation
 *
 * @description Fade in all other elements
 * @returns {gsap.core.Timeline} Gsap timeline
 */
export const heroFadeIn = (): gsap.core.Timeline =>
  gsap
    .timeline()
    .from(
      [".o-vertical-tagline", ".c-home-hero__social-icons", ".c-scroll-prompt"],
      { opacity: 0, duration: 1.5, ease: "linear" },
      "=+3.5"
    )

/**
 * @animation
 *
 * @description
 * @returns {gsap.core.Timeline} Gsap timeline
 */
export const featuredCasePtOne = (): gsap.core.Timeline =>
  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".featured-case__img-wrapper",
        start: "0px 85%",
        toggleActions: "play none none none",
        markers: false,
      },
    })
    .from(".featured-case__img-wrapper", {
      opacity: 0,
      duration: 0.6,
      ease: "expo.out",
    })
    .from(
      ".featured-case__meta",
      {
        y: "200%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
      },
      "-=0.3"
    )

/**
 * @animation
 *
 * @description
 * @returns {gsap.core.Timeline} Gsap timeline
 */
export const featuredCasePtTwo = (el: any): gsap.core.Timeline =>
  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".headline-link__title",
        start: () => {
          if ($desktop) {
            return "0px 82.5%"
          } else if ($tablet) {
            return "0px 95%"
          } else {
            return "0px 100%"
          }
        },
        toggleActions: "play none none none",
      },
    })
    .from(el, {
      y: "100%",
      opacity: 0,
      duration: 0.6,
      ease: "expo.out",
      stagger: 0.2,
    })
    .from(
      "#featured-link-arrow",
      {
        x: "-100%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
      },
      "-=0.9"
    )
    .from(
      "#featured-case__paragraph",
      {
        opacity: 0,
        duration: 0.3,
        ease: "linear",
      },
      "-=0.3"
    )

/**
 * @animation
 *
 * @description
 * @returns {gsap.core.Timeline} Gsap tween
 */
export const galleryScrub = (el: any): gsap.core.Tween =>
  gsap.fromTo(
    el,
    { y: "-20%" },
    {
      scrollTrigger: {
        trigger: el,
        scrub: true,
      },
      y: "20%",
      ease: "linear",
    }
  )

/**
 * @animation
 *
 * @description
 * @returns {gsap.core.Timeline} Gsap timeline
 */
export const casesCTA = (): gsap.core.Timeline => {
  const letters = Array.from(document.querySelectorAll(".svg-convincing__draw"))

  return gsap
    .timeline({
      scrollTrigger: {
        trigger: "#svg-convincing",
        start: "0px 85%",
      },
    })
    .from(letters, {
      drawSVG: "0",
      duration: 1.2,
      ease: "power1",
      // stagger: 1,
    })
    .from(
      "#more-cases-btn",
      {
        y: "100%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
      },
      "-=1"
    )
}
