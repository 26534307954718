import * as React from "react"
import { useRef, useEffect } from "react"

// Styles
import "./_contact-full-image.scss"

// Components
import { Section } from "../StyledComponents/layoutComponentStyles"
import ProgressiveImage from "react-progressive-image"
import contact_footer from "../../assets/images/contact_footer.jpg"

// Animations
import { galleryScrub } from "../../animations/pages/homeAnim"
import { pageTransitionInstant } from "../../animations/globalVariants"

interface ContactFullImageProps {}

/*





 



*/

const ContactFullImage: React.FunctionComponent<ContactFullImageProps> = () => {
  // Refs
  const imgRef = useRef<HTMLImageElement>(null)

  // Effects
  useEffect(() => {
    galleryScrub(imgRef.current)
  }, [])

  return (
    <Section initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant} key='contact-body'>
      <div className='contact-fullwidth-img__container'>
        <ProgressiveImage src={contact_footer} placeholder={contact_footer}>
          {(src: string) => <img src={src} alt='silk studio agency' className='contact-fullwidth-img' ref={imgRef} />}
        </ProgressiveImage>
      </div>
    </Section>
  )
}

export default ContactFullImage
