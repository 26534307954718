import * as React from "react"
import { useState, useEffect } from "react"

// Styles
import "./_contact-location.scss"

// Components
import { Section, Container, FlexRow } from "../StyledComponents/layoutComponentStyles"
import data from "../../data/openWeatherAPI"

// Animations
import { pageTransitionInstant } from "../../animations/globalVariants"

// Interfaces
interface ContactLocationProps {}

interface WeatherProps {
  main: {
    temp: number
  }
  weather: [
    {
      description: string
    }
  ]
}

/*









*/

const ContactLocation: React.FunctionComponent<ContactLocationProps> = () => {
  // States
  const [weatherData, updateWeatherData] = useState<WeatherProps>({ main: { temp: 0 }, weather: [{ description: "loading..." }] })

  useEffect(() => {
    // Fetch weather data
    fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${data.latitude}&lon=${data.longitude}&units=metric&appid=${data.key}`)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error("error")
        }
      })
      .then(res => {
        updateWeatherData(res)
      })
      .catch(error => console.log(error))
  }, [])

  return (
    <Section initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant} key='location-data'>
      <Container fourteenClm>
        <div className='o-location-data'>
          <FlexRow justifySpaceBetween>
            <p className='o-accent'>Shrewsbury, UK</p>
            <p className='o-accent'>{`${Math.round(weatherData?.main?.temp)}˚C, ${weatherData?.weather[0]?.description}`}</p>
          </FlexRow>
        </div>
      </Container>
    </Section>
  )
}

export default ContactLocation
