import * as React from "react"
import { useContext } from "react"
import CursorContext from "../../context/cursorContext"
import NewsletterContext from "../../context/newsletterContext"
import SapContext from "../../context/sapContext"

// Components
import { FaPaperPlane } from "react-icons/fa"
import { $mobile } from "../../utils/breakpoints"
import { socialAccounts } from "../../utils/socialAccounts"
import { socialAccount } from "../../@types/global-types"

/*









*/

const ContactInfoSection: React.FunctionComponent = () => {
  // Context
  const { setCursorState } = useContext(CursorContext)
  const { newsletterState, setNewsletterState } = useContext(NewsletterContext)
  const { setSapState } = useContext(SapContext)

  return (
    <div className='c-contact-info-container'>
      {/* <div className='c-contact-info__item'>
        <p className={$mobile ? "o-par-lrg" : undefined}>Call:</p>
        <a
          href='tel:+441743224262'
          onMouseEnter={() => setCursorState?.("is-link")}
          onMouseLeave={() => setCursorState?.("")}
          style={{ display: "inline-block" }}
        >
          <p>+44 1743 224262</p>
        </a>
      </div> */}
      <div className='c-contact-info__item'>
        <p className={$mobile ? "o-par-lrg" : undefined}>Email:</p>
        <a
          href='mailto:hello@silkstudio.co'
          onMouseEnter={() => setCursorState?.("is-link")}
          onMouseLeave={() => setCursorState?.("")}
          style={{ display: "inline-block" }}
        >
          <p>hello@silkstudio.co</p>
        </a>
      </div>
      <div className='c-contact-info__item'>
        <p>
          Looking to start a project? We've got a special place just for that. Whether you want to schedule a call or receive a
          proposal,&nbsp;
          {/* eslint-disable-next-line */}
          <span
            role='button'
            tabIndex={0}
            onClick={() => setSapState?.("start")}
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
            onMouseUp={() => setCursorState?.("")}
            className='o-inline-link is-bodylink'
          >
            click here
          </span>
          &nbsp;and we'll get things in motion...
        </p>
      </div>
      <div className='c-contact-social-icons'>
        {socialAccounts.map((account: socialAccount) => (
          <a
            href={account.link}
            className='social-icon__sml'
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
            key={account.link}
          >
            {account.icon}
          </a>
        ))}
        {/* eslint-disable-next-line */}
        <a
          href=''
          className='social-icon__sml'
          onMouseEnter={() => setCursorState?.("is-link")}
          onMouseLeave={() => setCursorState?.("")}
          onClick={e => {
            e.preventDefault()
            setNewsletterState?.(!newsletterState)
          }}
        >
          <FaPaperPlane />
        </a>
      </div>
    </div>
  )
}

export default ContactInfoSection
