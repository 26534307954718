import * as React from "react"
import { useContext, useState, useEffect } from "react"
import CursorContext from "../../context/cursorContext"
import autosize from "autosize"
import ReactGA from "react-ga"

// Components
import SubmitButton from "../Buttons/submitButton"
import Checkbox from "../Checkbox"
import WrapButton from "../Buttons/wrapButton"
import { FlexRow } from "../StyledComponents/layoutComponentStyles"
import { $desktop, $mobile } from "../../utils/breakpoints"

// Animations
import { motion, AnimatePresence } from "framer-motion"
import { fadeInOut } from "../../animations/globalVariants"

// Helpers
import { encode, handleFocus, handleBlur, validityFieldIDs } from "../../helpers/contactHelpers"

// Interfaces
interface ContactFormSectionProps {}

// Form Name
const formName = "Contact Enquiry"

/*









*/

const ContactFormSection: React.FunctionComponent<ContactFormSectionProps> = () => {
  // Context
  const { setCursorState } = useContext(CursorContext)

  // States
  const [successState, setSuccessState] = useState(false)
  const [formResults, setFormResults] = useState({})

  // Effects
  useEffect(() => {
    autosize(document.querySelector("#contact-message"))
  })

  // Helper Functions
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormResults({ ...formResults, [event.target.name]: event.target.value })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": formName, ...formResults }),
    })
      .then(() => {
        setTimeout(() => {
          setSuccessState(true)
        }, 1500)
        ReactGA.event({ category: "Form", action: "Contact Enquiry submitted", label: "Contact Enquiry Submission" })
      })
      .catch(error => alert(error))

    event.preventDefault()
  }

  return (
    <form
      name={formName}
      method='POST'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
      autoComplete='off'
      onSubmit={handleSubmit}
      className='c-lrg-form'
    >
      <input type='hidden' name='form-name' value={formName} />
      <p hidden>
        <label id={`${formName}-bot-label`}>
          Don't fill this out if you're human: <input name='bot-field' aria-labelledby={`${formName}-bot-label`} />
        </label>
      </p>
      <AnimatePresence exitBeforeEnter>
        {!successState && (
          <>
            <motion.fieldset key='form_fields' initial='initial' animate='animate' exit='exit' variants={fadeInOut}>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor='contact-name'>
                  Your name*
                </label>
                <input
                  type='text'
                  id='contact-name'
                  name='Contact Name'
                  className='c-lrg-form__input'
                  required
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor='contact-phone-number'>
                  Your phone number*
                </label>

                <input
                  type='tel'
                  id='contact-phone-number'
                  name='Contact Phone Number'
                  className='c-lrg-form__input'
                  required
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor='contact-email'>
                  Your email*
                </label>

                <input
                  type='email'
                  id='contact-email'
                  name='Contact Email'
                  className='c-lrg-form__input'
                  required
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor='contact-message'>
                  Your message*
                </label>
                <textarea
                  id='contact-message'
                  name='Contact Message'
                  className='c-lrg-form__input'
                  required
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                />
              </div>
              <div className='c-lrg-form__field is-gdpr'>
                <div onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")} role='presentation'>
                  <Checkbox id='contact-gdpr' name='Contact GDPR' value='true' required={true} onChange={handleChange} />
                </div>

                <p className='o-par-sml' style={{ fontStyle: "italic" }}>
                  I agree to the{" "}
                  <a
                    href={"/terms"}
                    // style={{ textDecoration: "underline" }}
                    className='o-inline-link is-bodylink'
                    target='_blank'
                    rel='noopener noreferrer'
                    onMouseEnter={() => setCursorState?.("is-link")}
                    onMouseLeave={() => setCursorState?.("")}
                  >
                    privacy policy
                  </a>{" "}
                  & consent to my details being collected.
                </p>
              </div>
            </motion.fieldset>
            <motion.div key='submit_button' initial='initial' animate='animate' exit='exit' variants={fadeInOut}>
              <FlexRow style={{ height: $desktop ? "5rem" : "12rem" }}>
                <SubmitButton
                  text='Submit'
                  validityFieldIDs={validityFieldIDs}
                  style={{
                    transform: $desktop ? "translate3d(-1rem,0,0)" : "translate3d(-2.5rem, 0, 0)",
                  }}
                />
              </FlexRow>
            </motion.div>
          </>
        )}

        {successState && <FormSuccess />}
      </AnimatePresence>
    </form>
  )
}

export default ContactFormSection

export const FormSuccess: React.FunctionComponent = () => {
  return (
    <motion.div
      key='form_success'
      initial='initial'
      animate='animate'
      exit='exit'
      variants={fadeInOut}
      className='contact-form__success'
    >
      {$desktop && (
        <>
          <h2>Thanks</h2>
          <p className='o-par-lrg'>
            Your form has been submitted <i>(hoorah!)</i>, thanks for that.
            <br />
            <br />
            We'll make sure to get back to you as soon as we can, in the meantime however, why not stick around and take a look at some
            of our latest work?
          </p>
          <WrapButton text='Work' link='/work/' />
        </>
      )}
      {!$desktop && (
        <>
          <h1>Thanks</h1>
          <p>
            Your form has been submitted <i>(hoorah!)</i>, we'll make sure to get back to you as soon as we can...
          </p>
          <WrapButton text='Home' link='/' centered={$mobile ? true : false} />
        </>
      )}
    </motion.div>
  )
}
