import * as React from "react"
import { useRef, useEffect } from "react"

// Styles
import "./_contact-hero.scss"

// Components
import { Section, Container } from "../StyledComponents/layoutComponentStyles"
import svg from "../../assets/icons/get-in-touch.svg"

// Animations
import { slideTitleParent, slideTitleChild, pageTransitionContact } from "../../animations/globalVariants"
import { motion } from "framer-motion"
import { createSlideText } from "../../animations/globalAnims"
import { gsap } from "gsap"
import { $mobile } from "../../utils/breakpoints"

// Interfaces
interface ContactHeroProps {}

/*









*/

const ContactHero: React.FunctionComponent<ContactHeroProps> = () => {
  // Refs
  const sectionContainerRef = useRef<HTMLDivElement>(null)
  const sectionRef = useRef<HTMLDivElement>(null)
  const parRef = useRef<HTMLParagraphElement>(null)
  const spinnerRef = useRef<HTMLDivElement>(null)

  // Effects
  useEffect(() => {
    gsap.to(sectionRef.current, {
      scrollTrigger: {
        start: "0px 0%",
        scrub: true,
      },
      y: "12%",
    })

    createSlideText(parRef.current, 0.9)
  }, [])

  return (
    <Section>
      <Container fourteenClm>
        <div ref={sectionContainerRef}>
          <div className='c-contact-hero' ref={sectionRef}>
            <motion.div initial='initial' animate='animate' exit='exit' variants={slideTitleParent}>
              <div className='u-overflow-reveal' style={{ display: "block" }}>
                <motion.h1 variants={slideTitleChild} className='o-type-xl'>
                  Reach out
                </motion.h1>
              </div>
              <div className='u-overflow-reveal' style={{ display: "block" }}>
                <motion.h1 variants={slideTitleChild} className='o-type-xl'>
                  and say hello
                </motion.h1>
              </div>
            </motion.div>
            <motion.div initial='initial' animate='animate' exit='exit' variants={pageTransitionContact}>
              <p className='o-par-lrg' ref={parRef}>
                Like what you see? Got a burning question? Just wanted to say hi? You've come to the right place. Either pick up the
                phone or slide into our dm's using the form below and we'll get back to you asap. We're all ears...
              </p>
            </motion.div>
          </div>
        </div>
      </Container>
      <motion.div
        initial='initial'
        animate='animate'
        exit='exit'
        variants={pageTransitionContact}
        onAnimationComplete={() => {
          const style = window.getComputedStyle(spinnerRef.current!).transform

          if (style === "none") {
            setTimeout(() => {
              console.log("onAnimComplete run")

              return gsap.to(spinnerRef.current, {
                scrollTrigger: {
                  start: "0px 0%",
                  scrub: 0.25,
                },
                rotation: 180,
                ease: "linear",
              })
            }, 1500)
          }
        }}
      >
        <div className='c-sh-spinner' ref={spinnerRef}>
          <img src={svg} alt='Say hello spinner' className='c-sh-spinner__text' />
          <span aria-label='a hand waving' role='img' className='c-sh-spinner__emoji'>
            ✌️
          </span>
        </div>
      </motion.div>
    </Section>
  )
}

export default ContactHero

// {!$mobile ? "o-par-lrg" : undefined}
