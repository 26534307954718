import * as React from "react"

// Styles
import "./_contact-body.scss"

// Components
import ContactInfoSection from "./ContactInfoSection"
import ContactFormSection from "./ContactFormSection"
import { Section, Container, FlexRow } from "../StyledComponents/layoutComponentStyles"

// Animations
import { pageTransitionContact } from "../../animations/globalVariants"

// Interfaces
interface ContactBodyProps {}

/*









*/

const ContactBody: React.FunctionComponent<ContactBodyProps> = () => {
  const borders = {
    borderBottom: "2px solid rgba(250, 250, 250, 0.5)",
    borderTop: "2px solid rgba(250, 250, 250, 0.5)",
  }

  return (
    <Section initial='initial' animate='animate' exit='exit' variants={pageTransitionContact} key='contact-body'>
      <Container fourteenClm style={borders}>
        <FlexRow alignStretch mobileDirColumn>
          <ContactInfoSection />
          <ContactFormSection />
        </FlexRow>
      </Container>
    </Section>
  )
}

export default ContactBody
